'use client';

import { useContext, useMemo } from 'react';
import { type User } from '@/authorization-sdk';
import { useRefreshAccessToken } from '@/lib/hooks/useRefreshAccessToken';
import { convertServicePermissionToString } from '@/utils/convertServicePermissionToString';
import { CurrentUserContext, type CurrentUserContextType } from './CurrentUserContext';

interface CurrentUserProviderProps {
  user: User;
  children: React.ReactElement;
}

export const CurrentUserProvider = ({ user, children }: CurrentUserProviderProps) => {
  const permissionStrings = user?.permissions?.map(permission => convertServicePermissionToString(permission));
  const contextProviderValue = useMemo(
    () => ({
      user,
      permissionStrings,
    }),
    [user, permissionStrings],
  );

  useRefreshAccessToken();

  return <CurrentUserContext.Provider value={contextProviderValue}>{children}</CurrentUserContext.Provider>;
};

export const useCurrentUser = (): CurrentUserContextType => useContext(CurrentUserContext);

interface CurrentUserProps {
  children: (props: CurrentUserContextType) => React.ReactElement;
}

export const CurrentUserConsumer = ({ children }: CurrentUserProps) => {
  const currentUser = useCurrentUser();

  return children(currentUser);
};
