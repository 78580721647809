import { createContext } from 'react';

export interface AppContextType {
  menuOpen: boolean;
  menuExpanded: boolean;
  setMenuOpen: (state: boolean) => void;
  setMenuExpanded: (state: boolean) => void;
  toggleMenu: () => void;
  currentFacilityId?: string;
}

export const AppContext = createContext<AppContextType>({
  menuOpen: false,
  menuExpanded: false,
  setMenuOpen: () => {},
  toggleMenu: () => {},
  setMenuExpanded: () => {},
});
