export class ResponseError extends Error {
  statusCode: number;
  constructor(message: string = 'Something went wrong', statusCode: number = 500, cause?: Error) {
    super(message);
    this.statusCode = statusCode;
    this.name = 'ResponseError';
    this.cause = cause;
  }
}

export class PermissionError extends ResponseError {
  constructor(message: string = 'Permission denied', statusCode: number = 403) {
    super(message);
    this.statusCode = statusCode;
    this.name = 'PermissionError';
  }
}

export class AuthenticationError extends ResponseError {
  constructor(message: string = 'User not authenticated, or session expired', statusCode: number = 401) {
    super(message);
    this.statusCode = statusCode;
    this.name = 'AuthenticationError';
  }
}
