import type { NextAuthOptions } from 'next-auth';
import OktaProvider from 'next-auth/providers/okta';
import { getSession, signOut } from 'next-auth/react';

export const authOptions: NextAuthOptions = {
  session: {
    strategy: 'jwt',
    maxAge: parseInt(process.env.NEXTAUTH_SESSION_LIFETIME || '86400'),
  },
  secret: process.env.NEXTAUTH_SECRET ?? '',
  debug: process.env.NODE_ENV === 'development',
  pages: {
    signIn: '/auth/login',
  },
  providers: [
    OktaProvider({
      id: 'okta',
      name: 'Okta',
      clientId: process.env.OKTA_CLIENT_ID ?? '',
      clientSecret: process.env.OKTA_CLIENT_SECRET ?? '',
      wellKnown: `${process.env.OKTA_ISSUER}/oauth2/default/.well-known/openid-configuration`,
      checks: ['pkce', 'state'],
      authorization: {
        params: {
          state: 'ready',
          scope: 'offline_access openid profile email',
        },
      },
    }),
  ],

  // Return the JWT in the session as id_token
  callbacks: {
    async jwt(data): Promise<any> {
      const { account, user, token } = data;
      // At login we get the token with the account
      if (account) {
        return { ...account, ...user };
      }
      return token;
    },
    async session(data) {
      const { session, token } = data;
      session.user.access_token = token.access_token;
      session.expires = token.expires_at;
      session.id_token = token.id_token as string;
      session.issuer = process.env.OKTA_ISSUER;
      session.okta_logout_redirect = process.env.OKTA_LOGOUT_REDIRECT;
      return session;
    },
  },
};

export const OKTA_LOGOUT = (issuer: string, id_token: string, redirect?: string) => {
  let url = `${issuer}/oauth2/default/v1/logout?id_token_hint=${id_token}`;
  if (redirect) {
    url = url + `&post_logout_redirect_uri=${redirect}`;
  }
  return url;
};

export const constructUrl = async (): Promise<string> => {
  const session = await getSession();
  const id_token = session?.id_token;
  const issuer = session?.issuer;
  const redirect = session?.okta_logout_redirect;
  if (id_token && issuer) {
    const url = OKTA_LOGOUT(issuer, id_token, redirect);
    return url;
  }
  throw new Error('Cannot find id_token and issuer.');
};
export const agaSignOut: () => any = async () => {
  const redirect = await constructUrl();
  await signOut({ redirect: false }).then(() => {
    if (window !== undefined) {
      window.location.href = redirect;
    }
  });
};
