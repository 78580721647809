'use client';

import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { isUserNotSignedError } from '@/utils/checkUserNotSigned';

type ErrorCause = {
  message: string;
  status: number;
};

const HTTP_FORBIDDEN_ERROR = 403;

// @see - https://codevoweb.com/setup-react-query-in-nextjs-13-app-directory/?utm_content=cmp-true
function QueryProvider({ children }: Readonly<React.PropsWithChildren>) {
  const client = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 5000,
        retry: (failureCount, error) => {
          if (isUserNotSignedError(error)) {
            return false;
          }

          const errorCause = (error as { cause: ErrorCause })?.cause;

          if (errorCause?.status === HTTP_FORBIDDEN_ERROR) {
            return false;
          }
          if (failureCount === 2) {
            return false;
          }

          return true;
        },
      },
    },
  });

  return (
    <QueryClientProvider client={client}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default QueryProvider;
