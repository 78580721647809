'use client';

import { Button, type ButtonProps } from '@/components/Base/Button';
import { agaSignOut } from '@/lib/auth';

export const SignOutButton = ({ ...props }: ButtonProps) => {
  return (
    <Button
      type="button"
      {...props}
      onClick={async () => {
        await agaSignOut();
      }}>
      Sign out
    </Button>
  );
};
