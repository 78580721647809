'use client';

import { usePathname } from 'next/navigation';
import { SessionProvider } from 'next-auth/react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getFacilityIdFromPathname } from '@/utils/getFacilityIdFromPathname';
import { AppContext, type AppContextType } from './AppContext';

interface AppProviderProps {
  facilityCookieValue?: string;
  children: React.ReactElement;
}

export const AppProvider = ({ facilityCookieValue, children }: AppProviderProps) => {
  const pathname = usePathname();

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [menuExpanded, setMenuExpanded] = useState<boolean>(true);
  const [currentFacilityId, setCurrentFacilityId] = useState(facilityCookieValue);

  const toggleMenu = useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  const contextProviderValue = useMemo(
    () => ({
      menuOpen,
      menuExpanded,
      setMenuOpen,
      setMenuExpanded,
      toggleMenu,
      currentFacilityId,
    }),
    [menuExpanded, menuOpen, toggleMenu, currentFacilityId],
  );

  // close menu on route change
  useEffect(() => {
    setMenuOpen(false);
    const facilityId = getFacilityIdFromPathname(pathname);
    if (facilityId) {
      setCurrentFacilityId(facilityId);
    }
  }, [pathname]);

  return (
    <SessionProvider>
      <AppContext.Provider value={contextProviderValue}>{children}</AppContext.Provider>
    </SessionProvider>
  );
};

export const useApp = (): AppContextType => useContext(AppContext);

interface AppProps {
  children: (props: AppContextType) => React.ReactElement;
}

export const AppConsumer = ({ children }: AppProps) => {
  const app = useApp();

  return children(app);
};
