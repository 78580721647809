import { useSession } from 'next-auth/react';
import { useEffect } from 'react';

export const useRefreshAccessToken = () => {
  const { data: session, status } = useSession();

  useEffect(() => {
    let intervalId: NodeJS.Timer;

    const getTimeDifference = (expires: number): number => {
      const currentTime = new Date().getTime();
      return expires * 1000 - currentTime;
    };

    if (status === 'authenticated') {
      const expiresIn = session?.expires; // When the session is supposed to expire

      const shouldRefresh = (): boolean => {
        if (!expiresIn) {
          return true;
        }
        const difference = getTimeDifference(expiresIn);
        return difference <= 10000;
      };

      const refresh = async () => {
        if (shouldRefresh() && typeof window !== 'undefined') {
          // Trigger a session refresh
          window.location.reload(); // Middleware handles the token refresh
        }
      };
      intervalId = setInterval(refresh, 1 * 60 * 1000); // Check every minute
    }

    return () => clearInterval(intervalId);
  }, [session, status]);
};
